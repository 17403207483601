import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FaDiscord, FaInstagram } from "react-icons/fa"
import { HiMail } from "react-icons/hi"
import '../styles/index.css'

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          email
        }
      }
    }
  `)

  const links = [
    {
      label: "Email",
      icon: HiMail,
      link: `mailto:${site.siteMetadata.email}`,
    },
    {
      label: "Discord",
      icon: FaDiscord,
      link: "https://discord.gg/sFETzRbjMR",
    },
    {
      label: "Instagram",
      icon: FaInstagram,
      link: "https://www.instagram.com/biologybowl/",
    },
  ]

  return (
    <footer className="flex-shrink-0">
      <div className="bg-gray-800">
        <div className="max-w-screen-xl mx-auto py-10 px-4 sm:px-6 md:flex md:items-center md:justify-between text-gray-400">
          <div className="flex justify-center md:order-2 space-x-2">
            {links.map(link => (
              <FooterLink href={link.link} icon={link.icon} label={link.label} />
            ))}
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base leading-6">
              &copy; {new Date().getFullYear()} {site.siteMetadata.title}. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}

const FooterLink = ({ href, label, icon: Icon }) => {
  return (
    <div className="inline-block pl-6">
      <a
        href={href}
        target="_blank" rel="noreferrer"
        className="hover:text-gray-300 transition duration-150 ease-in-out"
      >
        <span className="sr-only">{label}</span>
        <Icon className="w-6 h-6 fill-current" />
      </a>
    </div>
  )
}

export default Footer
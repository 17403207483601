import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import { useStaticQuery, graphql } from "gatsby";
import Partners from "../components/partners";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Team from "../data/team.json";
import Directors from "../data/directors.json";

const Subteam = ({ team, edges }) => {
  let Staff = [];
  for (let i = 0; i < Team.length; i++) {
    if (Team[i].team === team) {
      Staff.push(Team[i]);
    }
  }

  return (
    <div
      className={
        "px-2 md:px-8 mt-8 grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-3" +
        (Staff.length > 3
          ? "sm:grid-cols-4 lg:grid-cols-4"
          : "sm:grid-cols-3 lg:grid-cols-3")
      }
    >
      {Staff.map((member) => {
        return (
          <div
            key={member.name}
            className="flex flex-col text-center rounded-md overflow-hidden p-3"
          >
            <div className="text-center align-center justify-center items-center">
              <GatsbyImage
                className="rounded-full shadow-md w-32 h-32 lg:w-40 lg:h-40 mx-auto mb-2"
                image={getImage(
                  edges.find(
                    (x) => x.node.name === member.img
                    // x => x.node.name === "sritan"
                  ).node.childImageSharp.gatsbyImageData
                )}
                alt={member.name}
              />
            </div>
            <div>
              <p className="text-sm lg:text-base font-bold">{member.name}</p>
              <p className="text-sm lg-text-base text-blue-600">
                {member.team}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const AboutPage = () => {
  const { heroImage, teamImages } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      teamImages: allFile(filter: { relativeDirectory: { eq: "team" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 95
                width: 192
                height: 192
              )
            }
            name
          }
        }
      }
    }
  `);

  let edges = teamImages.edges;

  return (
    <Layout>
      <Seo
        title="About"
        description="Meet the team that works hard to bring you the International Biology Bowl."
      />
      <Hero image={heroImage} title="About" />
      <div className="max-w-screen-md mx-auto py-10 px-4 sm:px-6 block pt-20 text-lg">
        <div className="">
          <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-6">
            Our Mission
          </h1>
          <p className="text-gray-500">
            Through regional and international competitions, the IBB aims to{" "}
            <strong>enrich</strong> the lives of hundreds of its competitors by
            spreading the value of <strong>curiosity and collaboration</strong>.
            By creating thought-provoking, inspiring questions, the IBB aims to
            stimulate curiosity in biology and promote further exploration of
            this field. Moreover, by allowing participants to participate in
            teams, the IBB instills the values of collaboration and teamwork
            into competitors - an ideal characteristic utilized often in the
            research and medical community.
          </p>
          <br />
          <p className="text-gray-500">
            To support this main mission statement, the IBB firmly believes in
            incorporating both regional and international biology tournaments.
            The regional tournaments serve to give more personalized experiences
            to competitors on a smaller scale to compete and collaborate, while
            the international competition serves as a culmination of multiple
            regional tournaments, bringing the season to an end with hundreds of
            participants exploring and competing in biology.
          </p>
          <br />
          <p className="text-gray-500">
            Finally, the IBB believes in the accessibility of its tournaments,
            which is why we will always provide <strong>cost-free</strong>{" "}
            tournaments to participants. Moreover, to award excellence and to
            stimulate incentive, the IBB offers cash prizes. Over the past two
            years with multiple regional and international competitions, the IBB
            has given <strong>several thousands of dollars</strong> to award
            students <strong>all around the world</strong>.
          </p>
          <br />
          <p className="text-gray-500">
            We hope you will join us on a successful IBB season!
          </p>
          {/* <a href="https://docs.google.com/document/d/1CW4fOMTvCons2JCsKx0PhW-TYXkd169PfPRDPQKLxis/edit?usp=sharing" target="_blank" rel="noreferrer" className="bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out">Rules and Logistics</a> */}
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto py-10 px-4 sm:px-6 pt-20">
        <div className="text-center mb-2">
          <p className="uppercase inline font-semibold tracking-wide text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-blue-800">
            who we are
          </p>
        </div>
        <h1 className="text-2xl text-center tracking-tight leading-7 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-4xl mb-2 sm:mb-4">
          Executive Directors
        </h1>
        <p className="max-w-prose text-gray-500 text-lg text-center mx-auto mb-8">
          Comprised of biology enthusiasts from around the world, our team is
          committed to increasing interest in biology among high-school
          students.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {Directors.map((member) => {
            return (
              <div
                key={member.name}
                className="flex flex-col text-center sm:text-left sm:flex-row bg-gray-800 rounded-lg overflow-hidden p-6 shadow-lg"
              >
                <div className="w-100 text-center align-center justify-center items-center">
                  <GatsbyImage
                    className="rounded-full shadow-md w-48 h-48 sm:w-36 sm:h-36 md:h-48 md:w-48 sm:mr-6 sm:flex-shrink-0 mx-auto mb-2 sm:mb-0"
                    image={getImage(
                      teamImages.edges.find((x) => x.node.name === member.img)
                        .node.childImageSharp.gatsbyImageData
                    )}
                    alt={member.name}
                  />
                </div>
                <div>
                  <div className="flex w-100 text-center items-center align-center justify-center sm:items-start sm:align-start sm:justify-start">
                    <p className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gradient-to-r from-blue-500 to-blue-700 text-white mb-1">
                      {member.position}
                    </p>
                  </div>
                  <p className="text-2xl text-white font-bold mb-2">
                    {member.name}
                  </p>
                  <p className="text-gray-300 text-sm">{member.bio}</p>
                </div>
              </div>
            );
          })}
        </div>

        {/* <h1 className="text-2xl text-center tracking-tight leading-7 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-4xl mb-2 sm:mb-4 mt-16">
          Board
        </h1>
        <p className='max-w-prose text-gray-500 text-lg text-center mx-auto mb-8'>
          The IBB Board consists of leads who assist the organization in its management and operations.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {Board.map(member => {
            return (
            <div className='flex flex-col text-center sm:text-left sm:flex-row bg-gray-800 rounded-lg overflow-hidden p-6 shadow-lg'>
              <div className='w-100 text-center align-center justify-center items-center'>
                <GatsbyImage
                    className="rounded-full shadow-md w-48 h-48 sm:w-36 sm:h-36 md:h-48 md:w-48 sm:mr-6 sm:flex-shrink-0 mx-auto mb-2 sm:mb-0"
                    image={getImage(
                      teamImages.edges.find(
                        x => x.node.name === member.img
                      ).node.childImageSharp.gatsbyImageData
                    )}
                    alt={member.name}
                  />
                </div>
                <div>
                  <div className='flex w-100 text-center items-center align-center justify-center sm:items-start sm:align-start sm:justify-start'>
                    <p className='inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gradient-to-r from-blue-500 to-blue-700 text-white mb-1'>{member.position}</p>
                  </div>
                  <p className='text-2xl text-white font-bold mb-2'>{member.name}</p>
                  <p className='text-gray-300 text-sm'>{member.bio}</p>
                </div>
            </div>
          )})}
        </div>  */}

        <h1 className="text-2xl text-center tracking-tight leading-7 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-4xl mb-2 sm:mb-4 mt-16">
          Content Team
        </h1>
        <p className="max-w-prose text-gray-500 text-lg text-center mx-auto mb-8">
          The IBB content team is responsible for writing questions for all of
          our tournaments.
        </p>
        <Subteam team="Content" edges={edges} />

        <h1 className="text-2xl text-center tracking-tight leading-7 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-4xl mb-2 sm:mb-4 mt-16">
          Outreach Team
        </h1>
        <p className="max-w-prose text-gray-500 text-lg text-center mx-auto mb-8">
          The IBB outreach team is responsible for managing digital marketing
          campaigns and acquiring sponsorships.
        </p>
        <Subteam team="Outreach" edges={edges} />

        <h1 className="text-2xl text-center tracking-tight leading-7 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-4xl mb-2 sm:mb-4 mt-16">
          Tech Team
        </h1>
        <p className="max-w-prose text-gray-500 text-lg text-center mx-auto mb-8">
          The IBB tech team is responsible for maintaining the website and
          developing BioSphere, our incoming one-stop shop for learning and
          practicing for biology competitions.
        </p>
        <Subteam team="Tech" edges={edges} />

        <p className="text-lg text-center tracking-tight leading-7 sm:leading-none text-gray-900 sm:text-lg lg:text-lg xl:text-lg mb-2 sm:mb-4 mt-16">
          <span className="font-bold">Past Leadership:</span> Aneesh Swaminathan (Content), Evan Lan (Logistics), Tanishk Natu (Tournaments), Jonah Xu (Tournaments), Samuel Li (Tournaments), Sritan Motati (Tech), Emelie Nguyen (Outreach), Rohan Kalahasty (Founder), Srihan Balaji (Founder), Omkar Kovvali (Finance)</p>
      </div>
      <Partners />
    </Layout>
  );
};

export default AboutPage;
